/*
색상은 index.css에 정의되어 있습니다
*/

.page {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-height: 100vh;
  padding: 20px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.headerAndContents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.QRlogo-img {
  width: 100%;
  object-fit: contain;
  padding: 0 24px;
  max-width: 478px;
}

.error-title2 {
  color: #0c0d0d;
}

@media (min-width: 768px) {
  .error-title2 {
    margin-top: 90px;
  }
}

@media (min-width: 1024px) {
  .error-title2 {
    margin-top: 37px;
  }
}

.open-in-safari {
  margin-top: 8px;
}

.title {
  font-family: "Noto Sans", sans-serif;
  font-size: clamp(21px, 4vw, 36px);
  font-stretch: normal;
  font-style: normal;
  line-height: clamp(24px, 4.5vw, 38px);
  letter-spacing: 0px;
  text-align: center;
  color: var(--color-neutral-3-dark);
  padding: 0px 10px;
}

.titleKR {
  font-family: "Noto Sans KR", sans-serif;
  font-size: clamp(21px, 4vw, 36px);
  font-stretch: normal;
  font-style: normal;
  line-height: clamp(24px, 4.5vw, 38px);
  letter-spacing: 0px;
  text-align: center;
  color: var(--color-neutral-3-dark);
  padding: 0px 10px;
}

.safari {
  color: rgb(9, 146, 244);
}
.footer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 40px;
  width: 170px;
}

.description-container {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.description {
  font-family: AppleSDGothicNeo;
  font-size: clamp(11px, 1.5vw, 14px);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1px;
  color: #4a4a4a;
}

.qr-code {
  width: clamp(100px, 30vw, 300px);
  height: clamp(100px, 30vw, 300px);
  margin: 14px auto 0 auto;
}

.qr-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 840px;
  height: 530px;
  margin: 61px auto;
  padding: 24px 0px 33px 0px;
  border-radius: 8px;
  box-shadow: 0 7px 64px 0 rgba(0, 0, 0, 0.07);
  border: solid 1px #ecebed;
  background-color: var(--color-neutral-6-white);
}

aseeo-viewer {
  width: 100%;
  height: 330px;
}

aseeo-viewer#interaction {
  --poster-color: transparent;
}
.page3Progressbar {
  position: relative;
  top: 138px;
}
.SurveyText {
  width: 589px;
  height: 43px;
  margin: 0 5px 83px 0;
  font-family: AppleSDGothicNeo;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  position: relative;
  left: 118px;
}

/*  ------------ QR 모달 ------------ */
.modal-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-box {
  position: relative;
  padding: 4rem;
  width: clamp(300px, 70vw, 600px);
  background-color: white;
  border-radius: 2rem;
  box-shadow: 7px 7px 14px 2px rgba(0, 0, 0, 0.68);
}

.modal-title {
  font-size: clamp(1rem, 5vw, 30px);
  font-weight: bold;
}

.modal-instruction {
  font-size: clamp(1rem, 3vw, 1.2rem);
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  transition: 0.2s;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 2rem; /* x 길이 */
  width: 2px; /* x 두께 */
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.link-copy-btn {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 48px;
  border-radius: 6px;
  padding: 0;
  border: none;
  background-color: #fea800;
  font-family: SFProText;
  font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-decoration: none;
  color: var(--color-neutral-6-white);
  cursor: pointer;
}

.infomation-step {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #646466;
}

.information-center {
  margin: 0px auto;
  margin-top: 2rem;
  padding: 0px 10px;
  font-family: Apple SD Gothic Neo;
  font-weight: 500;
  font-size: 14px;
}

.Here-image {
  position: fixed;
  left: 77vw;
  top: 80vh;
}

@media (min-width: 360px) and (max-width: 390px) {
  /* 375px 아이폰 텐, SE 2세대 11pro */
  .Arrow-image {
    position: fixed;
    left: 64vw;
    top: -1px;
    transform: scale(75%, 90%);
  }
}

@media (min-width: 400px) and (max-width: 430px) {
  /* 414px 아이폰 11, 11pro max, 6+, 7+, 8+, Xs Max 등 큰모델 */
  .Arrow-image {
    position: fixed;
    left: 65vw;
    top: 10px;
    transform: scale(85%, 95%);
  }
}

@media (min-width: 319px) and (max-width: 321px) {
  /* 320px 아이폰 4인치 모델 SE 1세대 등 */
  .Arrow-image {
    position: fixed;
    left: 62vw;
    top: -5px;
    transform: scale(58%, 90%);
  }
}

.orange-text-color {
  color: #6073ff;
}
