body {
  /* 모바일 브라우저 터치 시 어색한 문제 해결하기 위함 TODO 테스트 필요 */
  overflow: hidden;
  /* 모바일 브라우저 터치 시 어색한 문제 해결하기 위함 TODO 테스트 필요 */
  touch-action: none;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  overflow-y: scroll;
  /* overflow-x: hidden; */
  background-color: white;
  /* height: 100vh; */
  height: calc(var(--vh, 1vh) * 100);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* @font-face { */
/* font-family: 'Noto sans'; */
/* src: url('./fonts/NotoSansKR-Regular.otf') format('otf'); */
/* src: url('./fonts/NotoSansKR-Black.otf') format('otf'); */
/* font-family: 'NanumPenScript-Regular'; */
/* src: url('./fonts/NotoSansKR-Regular.otf') format('otf'); */
/* src: url('./fonts/NanumPenScript-Regular.ttf') format('otf'); */
/* } */
